<template>
  <div>
    <section :class="itemView">
      <b-card
        v-for="item in offers"
        :key="item.id"
        class="ecommerce-card py-1 my-50"
        no-body
      >
        <b-link
          :to="{ name: 'trading-offers-preview', params: { id: item.id } }"
        >
          <div class="item-img justify-content-center align-items-center text-center cursor-pointer">
            <b-img
              :alt="`${item.name}-${item.id}`"
              fluid
              class="card-img-top"
              :srcset="resolveProductsData(item).img"
            />
          </div>
        </b-link>

        <!-- Product Details -->
        <b-link
          :to="{ name: 'trading-offers-preview', params: { id: item.id } }"
        >
          <b-card-body>
            <div class="item-wrapper">
              <div />
              <div>
                <h6 class="item-price">
                  {{ item.currency | currencySymbol }}{{ item.price }}
                </h6>
              </div>
            </div>
            <div class="product-info">
              <div>
                <h4 class="item-name">
                  {{ resolveProductsData(item).title }}
                </h4>
                <div
                  v-if="resolveProductsData(item).productCode.length || resolveProductsData(item).productGroup.length"
                  class="d-flex align-items-center product-group"
                >
                  <h6 class="mb-0 mr-1">
                    {{ resolveProductsData(item).productGroup }}
                  </h6>
                  <span class="text-muted">
                    {{ resolveProductsData(item).productCode }}
                  </span>
                </div>
              </div>
              <div class="ml-1">
                <div class="d-flex align-items-center">
                  <span>{{ $t('quantity') }}:</span>
                  <h6 class="mb-0 ml-1">
                    {{ resolveProductsData(item).qty }} {{ $t('pcs') }}
                  </h6>
                </div>
                <div class="d-flex align-items-center">
                  <span>{{ $t('unitPrice') }}:</span>
                  <h6 class="mb-0 ml-1">
                    {{ item.currency | currencySymbol }}{{ handleItemPrice(resolveProductsData(item).price) }}
                  </h6>
                </div>
                <div class="d-flex align-items-center">
                  <span>{{ $t('totalCost') }}:</span>
                  <h6 class="mb-0 ml-1">
                    {{ item.currency | currencySymbol }}{{ handleItemPrice(item.price) }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between bottom-info">
              <div>
                <b-badge
                  v-if="item.products.length - 1 > 0"
                  variant="light-warning"
                  class="qty-badge"
                >
                  {{ resolveProductsData(item).productsInfo }}
                </b-badge>
              </div>
              <div class="d-flex align-items-center lead-time">
                <h6 class="m-0 mr-1">
                  {{ $t('leadTime') }}:
                </h6>
                <b-badge
                  variant="light-primary"
                >
                  {{ $t('daysMore', { qty: item.lead_time }) }}
                </b-badge>
              </div>
            </div>
          </b-card-body>
        </b-link>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                {{ item.currency | currencySymbol }}{{ handleItemPrice(item.price) }}
              </h4>
            </div>
          </div>
          <b-button
            variant="light"
            class="btn-wishlist"
            :to="{ name: 'trading-offers-preview', params: { id: item.id } }"
          >
            <span>{{ $t('moreInfo') }}</span>
          </b-button>

          <b-button
            v-if="!isBuyer && item.can_edit"
            variant="primary"
            class="btn-cart"
            :to="{ name: 'trading-offers-add', params: { id: item.id } }"
          >
            <span>{{ $t('edit') }}</span>
          </b-button>

          <b-button
            v-if="!item.is_owner"
            variant="primary"
            class="btn-cart"
            @click.stop="buyModal(item.id)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>{{ $t('buy') }}</span>
          </b-button>
        </div>
      </b-card>
    </section>
    <BuyOfferModal
      :id="buyOffer"
      v-model="buyOffer"
    />
  </div>
</template>

<script>
import BuyOfferModal from '@/views/apps/components/modals/BuyOfferModal.vue';
import { resolveProductsData } from '@/utils/offerUtils.js';
import {
  BBadge, BButton,
  BCard, BCardBody,
  BImg, BLink,
} from 'bootstrap-vue';

export default {
  name: 'OpenOfferList',
  components: {
    BuyOfferModal,
    BCard,
    BBadge,
    BCardBody,
    BLink,
    BImg,
    BButton,
  },
  props: {
    itemView: {
      type: String,
      default: 'list-view',
    },
    offers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      buyOffer: null,
    };
  },
  computed: {
    isBuyer() {
      return this.$store.getters['profile/isBuyerOnly'];
    },
  },
  methods: {
    resolveProductsData,
    buyModal(id) {
      this.buyOffer = id;
    },
    handleItemPrice(price) {
      if (price % 1 !== 0) {
        return price.toFixed(2);
      }
      return price;
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";

.list-view {
  .bottom-info {
    border-top: 1px solid $border-color;
    padding-top: 1rem;
  }
  .product-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
.product-group {
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  background: $light;
}
.dark-layout {
  .list-view {
    .bottom-info {
      border-top: 1px solid $table-dark-border-color;
      padding-top: 1rem;
    }
  }
  .product-group {
    background: $theme-dark-body-bg;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card-img-top {
  max-height: 150px;
  width: auto;
}
.ecommerce-card {
  border: 1px solid $border-color;
}
</style>
